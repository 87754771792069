<style scoped>
li {
  padding: 3px;
}
</style>

<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand" exact> Home </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              to="/live"
              class="btn"
              :class="
                pathNameHeader === '/live' || pathNameHeader === '/'
                  ? 'btn-info'
                  : 'btn-outline-info'
              "
              id="live"
              exact
            >
              Live Forecast
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/daily"
              class="btn"
              :class="
                pathNameHeader === '/daily' ? 'btn-info' : 'btn-outline-info'
              "
              id="daily"
              exact
            >
              Daily Forecast
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/alerts"
              class="btn"
              :class="
                pathNameHeader === '/alerts' ? 'btn-info' : 'btn-outline-info'
              "
              id="alerts"
              exact
            >
              National Weather Alerts
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: ["pathNameHeader"],
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.footer {
    /* position: relative;
    bottom: 0;
    width: 100%; */
    /* height: 60px; */
    line-height: 60px;
    background-color: #000;
}

.footer > .container {
    padding-right: 15px;
    padding-left: 15px;
}
</style>

<template>
    <footer class="footer">
      <div class="container">
        <span class="text-light fs-5">&copy; Sachin Dudhara</span>
      </div>
    </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
.card-body {
  border: black 1px solid;
}

.col {
  padding: 0px;
}

p.tempdata {
  font-size: 0.9rem;
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
      />
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
      />
    </symbol>
    <symbol
      id="exclamation-triangle-fill"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path
        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
      />
    </symbol>
  </svg>

  <div class="container">
    <div class="row">
      <div v-if="alertData && alertData.length > 0">
        <div class="col" v-for="alert in alertData" :key="alert.sender_name">
          <h3 class="text-center">Toronto</h3>
          <div class="card-body alert alert-danger">
            <h5 class="card-title d-flex align-items-center">
              <svg
                class="bi flex-shrink-0 me-2"
                width="24"
                height="24"
                role="img"
                aria-label="Danger:"
              >
                <use xlink:href="#exclamation-triangle-fill" />
              </svg>
              Alerts in Effect
            </h5>
            <h5 class="card-title"> {{ alert.sender_name }}</h5>
            <h3 class="card-title text-center">{{ alert.event }}</h3>
            <!-- <p class="card-title">{{ alert.start }}</p>
            <p class="card-text">{{ alert.end }}</p> -->
            <p class="card-text">{{ alert.description }}</p>
            <p class="card-text">Tags : {{ alert.tags[0] }}</p>
          </div>
        </div>
      </div>
      <div class="col" v-else>
        <div class="card-body">
          <h3 class="text-center">Toronto</h3>
          <h5 class="card-title alert alert-success d-flex align-items-center">
            <svg
              class="bi flex-shrink-0 me-2"
              width="24"
              height="24"
              role="img"
              aria-label="Success:"
            >
              <use xlink:href="#check-circle-fill" />
            </svg>
            No Alerts in effect currently
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alertData: [],
    };
  },
  methods: {
    convertEpochToLocalTime(timeEpoch) {
      const d = new Date(timeEpoch * 1000);
      return d.toLocaleString();
    },
  },
  async created() {
    try {
      const response = await this.axios.get(
        "https://api.openweathermap.org/data/2.5/onecall",
        {
          params: {
            lat: "43.7001",
            lon: "-79.4163",
            exclude: "current,minutely,hourly,daily",
            appid: "e5efd450378f3899e0541e6696a210d5",
          },
        }
      );
      //console.log(response.data);
      this.weatherData = response.data;
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {
    this.$emit("path-name", this.$route.path);
  },
};
</script>
import { createRouter, createWebHistory } from 'vue-router';

import LiveForecast from '@/components/LiveForecast.vue';
import DailyForecast from '@/components/DailyForecast.vue';
import WeatherAlerts from '@/components/WeatherAlerts.vue';

const routes = [
    {
        path: "/",
        component: LiveForecast
    },
    {
        path: "/live",
        component: LiveForecast
    },
    {
        path: "/daily",
        component: DailyForecast
    },
    {
        path: "/alerts",
        component: WeatherAlerts
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;


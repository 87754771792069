import { createApp } from 'vue';
import axios from 'axios';
import vueaxios from 'vue-axios';
import router from './routes/routes';

import App from './App.vue';

const app = createApp(App);

app.config.productionTip = true;
app.config.silent = true;

app.use(vueaxios,axios);
app.use(router);

app.mount('#app');

<style>
</style>

<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card-body">
          <div v-if="weatherData">
            <h3 class="card-title text-center">{{ weatherData.name }}</h3>
            <h3 class="card-title text-center">{{ timestamp }}</h3>
            <p class="card-text text-center fs-1">
              {{ (weatherData.main.temp - 273.15).toFixed(0) }}&deg;C
              <img
                :src="
                  'https://openweathermap.org/img/wn/' +
                  weatherData.weather[0].icon +
                  '@2x.png'
                "
                :alt="weatherData.name"
              />
            </p>
            <p class="card-text text-center fs-4">Lat,Lon</p>
            <p class="card-text text-center fs-5">
              {{ weatherData.coord.lat }},{{ weatherData.coord.lon }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl">
      <h2 style="background-color:red;">Hello Sachin</h2>
  </div>
</template>

<script>
export default {
  data() {
    return {
      weatherData: null,
      timestamp: null,
    };
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
  },
  async created() {
    try {
      const response = await this.axios.get(
        "https://api.openweathermap.org/data/2.5/weather",
        {
          params: {
            q: "Toronto",
            appid: "e5efd450378f3899e0541e6696a210d5",
          },
        }
      );
      this.weatherData = response.data;
    } catch (error) {
      console.log(error);
    }
    try {
      this.getNow();
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {
    this.$emit("path-name", this.$route.path);
  },
};
</script>
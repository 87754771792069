<style>

html {
  font-family: "Jost", sans-serif;
}


</style>

<template>
  <div>
    <app-header :pathNameHeader="appPathName"></app-header>
    <router-view @path-name="pathName"></router-view>
    <app-footer></app-footer>
  </div>
</template>

<script>

import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  components: {
    AppHeader,
    AppFooter
  },
  data() {
    return {
      appPathName: '',
    };
  },
  methods: {
    pathName(value) {
       this.appPathName=value;
    },
  }
};
</script>


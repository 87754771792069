<style>
.card-body {
  border: black 1px solid;
}

.col {
  padding: 0px;
}

.row {
  padding-bottom: 20px;
}

p.tempdata {
  font-size: 0.9rem;
}
</style>

<template>
  <div class="container">
    <h3 class="text-center">Toronto</h3>
    <div class="row">
      <div
        class="col"
        v-for="weatherDate in weatherData.daily"
        :key="weatherDate.dt"
      >
        <div class="card-body">
          <h5 class="card-title">
            {{ convertEpochToLocalTime(weatherDate.dt) }}
          </h5>
          <p class="card-title">Min/Max Temp</p>
          <p class="tempdata card-title">
            {{ (weatherDate.temp.min - 273.15).toFixed(0) }} /
            {{ (weatherDate.temp.max - 273.15).toFixed(0) }} &deg;C
          </p>
          <img
            :src="
              'https://openweathermap.org/img/wn/' +
              weatherDate.weather[0].icon +
              '@2x.png'
            "
            :alt="weatherDate.weather[0].description"
          />
          <p class="card-title">
            {{ weatherDate.weather[0].description }}
          </p>

          <p class="card-text">
            Wind  - {{ weatherDate.wind_speed }} m/s
          </p>

          <p class="card-text">
            Wind Gust - {{ weatherDate.wind_gust }} m/s
          </p>

          <p class="card-text">
            Sunrise - {{ convertEpochToLocalTime(weatherDate.sunrise) }}
          </p>
          <p class="card-text">
            Sunset - {{ convertEpochToLocalTime(weatherDate.sunset) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      weatherData: [],
    };
  },
  methods: {
    convertEpochToLocalTime(timeEpoch) {
      const d = new Date(timeEpoch * 1000);
      return d.toLocaleString("en-CA",{
        weekday:"short",
        day:"2-digit"
      });
    },
  },
  async created() {
    try {
      const response = await this.axios.get(
        "https://api.openweathermap.org/data/2.5/onecall",
        {
          params: {
            lat: "43.7001",
            lon: "-79.4163",
            exclude: "current,minutely,hourly,alerts",
            appid: "e5efd450378f3899e0541e6696a210d5",
          },
        }
      );
      this.weatherData = response.data;
    } catch (error) {
      console.log(error);
    }
  },
  mounted(){
  this.$emit('path-name',this.$route.path);
  }
};
</script>